import {
  WFAuth,
  WFAuthMiddleware,
  navigate,
} from "@xatom/core";
import { PAGE } from "../config";

const KEY = "@opt-auth-public";

export const publicAuth = new WFAuth<
  {
    email: string;
    firstName: string;
    lastName: string;
  },
  "NONE" | "USER",
  {}
>();

export const publicMiddleware = new WFAuthMiddleware(
  publicAuth
);
publicAuth.setRole("NONE");
export const loadPublicAuthFromStorage = () => {
  const _data = JSON.parse(
    localStorage.getItem(KEY) || "{}"
  );
  if (
    "email" in _data &&
    "firstName" in _data &&
    "lastName" in _data
  ) {
    publicAuth.setUser({
      email: _data.email,
      firstName: _data.firstName,
      lastName: _data.lastName,
    });
    publicAuth.setRole("USER");
  }
};

export const setAuth = (
  email: string,
  firstName: string,
  lastName: string
) => {
  localStorage.setItem(
    KEY,
    JSON.stringify({ email, firstName, lastName })
  );
  publicAuth.setUser({
    email,
    firstName,
    lastName,
  });
  publicAuth.setRole("NONE");
};

export const logout = () => {
  publicAuth.setUser(null);
  publicAuth.setRole("NONE");
  localStorage.setItem(KEY, "{}");
  navigate(PAGE.login);
};
