export const PAGE = {
  home: "/",
  authCheck: "/auth/check",
  login: "/auth/login",
  logout: "/auth/logout",
  dashboard: "/dashboard",
  mySites: "/dashboard/my-sites",
  addSitePlan: "/dashboard/add-site-plan",
};

export const SERVER_ENDPOINT =
  window.location.host.includes("webflow-ext.com")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : window.location.host.includes("webflow.io")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : window.location.host.includes("optomate.ai")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : `http://localhost:4001`;

export const GQL_ENDPOINT = `${SERVER_ENDPOINT}/api/graphql`;

export const CLIENT_ID =
  "6eafb655cc5485e78efbda2e2a4e5d072680433484da3d01071c242f306c4efa";