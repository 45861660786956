import { WFRoute, navigate } from "@xatom/core";
import { PAGE } from "../config";
import { logout, publicMiddleware } from "../auth/public";

export const routes = () => {
  new WFRoute(PAGE.authCheck).execute((data) => {
    import("../modules/auth/check")
      .then(({ checkAuth }) => {
        {
          checkAuth(data as any);
        }
      })
      .catch(console.log);
  });
  new WFRoute(PAGE.login)
    .withMiddleware(publicMiddleware, "NONE", "allow", {
      onError() {
        navigate(PAGE.mySites);
      },
    })
    .execute(() => {
      import("../modules/auth/login")
        .then(({ login }) => {
          {
            login();
          }
        })
        .catch(console.log);
    });
  new WFRoute(PAGE.logout).execute(() => {
    import("../modules/auth/logout")
      .then(({ logout }) => {
        {
          logout();
        }
      })
      .catch(console.log);
  });
  new WFRoute(`${PAGE.dashboard}/(.*)`)
    .withMiddleware(publicMiddleware, "USER", "allow", {
      onError() {
        logout();
      },
    })
    .execute(() => {
      import("../modules/auth/me")
        .then(({ me }) => {
          me(() => {
            new WFRoute(PAGE.mySites).execute(() => {
              import("../modules/sites/mySite")
                .then(({ mySite }) => {
                  {
                    mySite();
                  }
                })
                .catch(console.log);
            });
            new WFRoute(PAGE.addSitePlan).execute(
              (params) => {
                import("../modules/sites/addPlan")
                  .then(({ renderAddPlan }) => {
                    {
                      renderAddPlan(params as any);
                    }
                  })
                  .catch(console.log);
              }
            );
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
};
